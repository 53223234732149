import { Controller } from "@hotwired/stimulus"
import { initSelect2 } from 'shared/select2_init'; 
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';
// Connects to data-controller="back--statistics--v2--statistics-separate-shop-select"
export default class extends Controller {
  connect() {
    initSelect2($(this.element).find('select:visible'))
    $(this.element).find('select').on('select2:select', (e) => {
      this.loadPage($(e.currentTarget).val())
    })      

    if($(this.element).data().pagination) {
      this.loadPage('00000000-0000-0000-0000-000000000000')
    } else {
      this.setDataTable()
    }
      
    
    
  }

  loadPage(shop_id) {
    let page
    if($(`#statistics-v2-separate-shops__${shop_id} .pagination_container .current`).length > 0) {
      page = parseInt($(`#statistics-v2-separate-shops__${shop_id} .pagination_container .current`).text())
    } else {
      page = 1
    }
    $('.statistics-v2-separate-shops-tab.active .statistics-v2-cache-container').html('')
    $('.statistics-v2-separate-shops-tab').removeClass('active')
    $(`#statistics-v2-separate-shops__${shop_id}`).addClass('active')
    console.log(`#statistics-v2-separate-shops-tab__${shop_id}`)
    ajaxTurboStream(
      `/back/statistics/v2/products/load_cache_page?page=${page}&shop_id=${shop_id}&cache_key=${$('#statistics-cache-content').data().cacheKey}&group_by_day=${$('#statistics-cache-content').data().groupByDay}`,
      false
    )
  }

  selectTab(e) {
    if(!$(e.currentTarget).hasClass('active')) {
      $(this.element).find('li.active').removeClass('active')
      $('.statistics-v2-separate-shops-tab').removeClass('active')
      $(e.currentTarget).addClass('active')
      $(`#statistics-v2-separate-shops__${$(e.currentTarget).data().target}`).addClass('active')
      this.setDataTable()
    }
  }

  setDataTable() {
    if($("#statistics_v2_products_tables .dataTables_scrollHead:visible").length == 0) {
      $("#statistics_v2_products_tables table:visible").DataTable({
        "pageLength": 100000,
        'order': [],
        'scrollX': true,
        "caseInsensitive": true
      })      
    }
  }
}
