import Back__Statistics__V2__StatisticsAbstractFilterController from "./statistics_abstract_filter_controller"
import { ExportXlsx } from '../../../../shared/export_xlsx';
// Connects to data-controller="back--statistics--v2--statistics-products"
export default class extends Back__Statistics__V2__StatisticsAbstractFilterController {
  get autoRefresh() {
    return true
  }

  refresh(params) {
    $('#loading_app').addClass('d-flex')
    let url = `/back/statistics/v2/products/load_stats?${new URLSearchParams(params).toString()}`
    $.ajax({url: url, dataType: 'script'}).done(() => {
      $('#loading_app').removeClass('d-flex')  
    })
  }

  exportXlsx() {
    if($('#statistics-cache-content').length > 0) {
      let url = `/back/statistics/v2/products/export_cache?cache_key=${$('#statistics-cache-content').data().cacheKey}`
      if($('#statistics-cache-content').data().separatedShops) {
        url += "&separated_shops=true"
      }
      window.location = url
    } else {
      new ExportXlsx('statistics_v2_products_tables', {}, 'export-statistics-products').render()
    }
    
  }  

  showProduct(e) {
    initChartVar()
    let params = this.prepareParams()
    let data = $(e.currentTarget).data()
    params.measureSymbol = data.measureSymbol
    params.productUniqueId = data.productUniqueId
    params.productVariationUniqueId = data.productVariationUniqueId
    params.shopId = data.shopId
    params.date = data.date

    let title
    if(params.date) {
      title = `${$(e.currentTarget).find('td').eq(1).text()} le ${params.date}`
    } else {
      title = `${$(e.currentTarget).find('td').eq(0).text()} - ${$('#statistics-v2-filters__rangedatepicker-1 span').text().toLowerCase()}`
    }

    initModal()
    $('.modal-dialog').addClass('modal-xl')
    $('.modal-title').text(`
      Répartition des ventes de ${title}
    `)
    $('.modal-body').html(`
      <canvas class='stats_container' height=400 data-url="v2/products/affluence?${new URLSearchParams(params).toString()}" data-filter='.filter_card'></canvas>  
    `)
    $('.modal-body').addClass('loading_card')
    
    $('.modal-footer').hide()
    $('#modal_rails_5').modal('show')
    var myInterval = setInterval(function(){
      if($('canvas.stats_container:visible').length) {
        loadCanvasStats($(".modal canvas"))
        clearInterval(myInterval);
        $('#loading_app').removeClass('d-flex')
      }
    }, 50);
  }  
}
